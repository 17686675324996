var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "container",
    },
    [
      _vm._m(0),
      _c(
        "b-card",
        {
          attrs: {
            header: "Vip's Analysis",
            "header-tag": "header",
            "footer-tag": "footer",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "m-0 h6",
                        },
                        [_vm._v("Vip's Analysis")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            size: "sm",
                            variant: "primary",
                          },
                        },
                        [_vm._v("Back")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            {
              staticClass: "row my-3",
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-4 d-flex justify-content-center",
                },
                [_c("p", [_vm._v("Student's Status : Show")])]
              ),
              _c(
                "div",
                {
                  staticClass: "col-4 d-flex justify-content-center",
                },
                [_c("p", [_vm._v("Student's Name : Yuki Hsu")])]
              ),
              _c(
                "div",
                {
                  staticClass: "col-4 d-flex justify-content-center",
                },
                [_c("p", [_vm._v("Student's Level : 2")])]
              ),
            ]
          ),
          _c("hr", {
            staticClass: "mb-3",
          }),
          _vm._l(_vm.fields, function (field, index) {
            return [
              _c(
                "responsive-input",
                {
                  attrs: {
                    title: field,
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.options,
                    },
                    model: {
                      value: _vm.evaluation[field].value,
                      callback: function ($$v) {
                        _vm.$set(_vm.evaluation[field], "value", $$v)
                      },
                      expression: "evaluation[field].value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "row",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col",
                    },
                    [
                      _c("b-form-textarea", {
                        staticClass: "mt-1 mb-3",
                        attrs: {
                          placeholder: _vm.placeholder,
                          rows: 6,
                          disabled: "",
                        },
                        model: {
                          value:
                            _vm.cannedResponses[_vm.type][field][
                              _vm.evaluation[field].value
                            ],
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.cannedResponses[_vm.type][field],
                              _vm.evaluation[field].value,
                              $$v
                            )
                          },
                          expression:
                            "cannedResponses[type][field][evaluation[field].value]",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          }),
          _c(
            "div",
            {
              staticClass: "row",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "col d-flex justify-content-center align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                      },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "row mb-4",
      },
      [
        _c(
          "div",
          {
            staticClass: "col-4 d-flex justify-content-center",
          },
          [
            _c(
              "p",
              {
                staticClass: "h6",
              },
              [_vm._v("Class Time : 2022-01-08 17:30:00")]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-4 d-flex justify-content-center",
          },
          [
            _c(
              "p",
              {
                staticClass: "h6",
              },
              [_vm._v("Material Title : Convenience Store (Basic)*")]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-4 d-flex justify-content-center",
          },
          [
            _c(
              "p",
              {
                staticClass: "h6",
              },
              [_vm._v("Material Level : 2")]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }